/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-01-12",
    versionChannel: "nightly",
    buildDate: "2025-01-12T00:07:09.053Z",
    commitHash: "28c17fdd8cb03ab201bdf049d484faa460d6fdd3",
};
